<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-50 md-small-size-100">
      <h2>Deslogueado</h2>
    </div>
  </div>
</template>
<script>
import { firebase } from "@/firebase";
export default {
  mounted() {
    this.$store.commit("setUser", null);
    firebase.auth().signOut();
    setTimeout(() => this.$router.push({ name: "login" }), 3000);
  },
};
</script>
<style scoped>
h2 {
  font-size: 2em;
  color: #fff;
  letter-spacing: 4px;
  font-weight: 400;
}
</style>
